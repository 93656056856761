import SkillList from "../element/SkillList.js"
import React, {useState} from "react"
import '../css/Skill.css';

function Skill() {

  const [opacity,
    setOpacity] = useState(false)
  const changeOpacity = () => {
    if (window.scrollY >= 800) {
      setOpacity(true)
    } else {
      setOpacity(false)
    }
  }

  window.addEventListener('scroll', changeOpacity)

  return (
    <div className="selection content-background" id="skills">
      <div className="container">
        <div className={opacity
            ? "skill-scroll"
            : ""}>
          <h1 className="text-light skill-text">
            Skills
          </h1>
          <div className="row">
            <SkillList judul="Front-End Developer" icon="front"/>
            <SkillList judul="Back-End Developer" icon="back"/>
            <SkillList judul="Multimedia" icon="design"/>
            <SkillList judul="Office" icon="office"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skill;