import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import Welcome from './content/Welcome.js';
import Nav from './content/Nav.js';
import Intro from './content/Intro.js';
import AboutMe from './content/AboutMe.js';
import Skill from './content/Skill.js';
import Project from './content/Project.js';
import Contact from './content/Contact.js';
import Footer from './content/Footer.js';
import SubNav from './content/SubNav.js';
import Work from './content/Work.js';
import AllProject from './subcontent/AllProject';
import DetailProject from './subcontent/DetailProject';
import AudioControl from './content/AudioControl.js';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

ReactGA.initialize('G-BEHMQHW1EW');

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={loading ? <Welcome/> : <Navigate to="/home" replace />} />
        <Route path='/home' element={<div><Nav/> <Intro/> <AboutMe/> <Skill/> <Project/> <Work/> <Contact/> <Footer/></div>} />
        <Route path='/all/project' element={<div><SubNav/><AllProject/></div>} />
        <Route path='/detail/project/:id' element={<div><SubNav/><DetailProject/></div>} />
      </Routes>
      <div className='fixed-bottom margin-musik'>
        <AudioControl/>
      </div>
    </Router>
  );
}

export default App;
