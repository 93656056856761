import sikuismhs from "../images/si-kuis-mhs.png";
import sikuismhsdetail from "../images/si-kuis-mhs-detail.png";
import sipresensignp from "../images/si-presensi-gnp.png";
import sipresensignpdetail from "../images/si-presensi-gnp-detail.png";
import simanifestgnp from "../images/si-manifest-gnp.png";
import simanifestgnpdetail from "../images/si-manifest-gnp-detail.png";
import sikurirgnp from "../images/si-kurir-gnp.png";
import sikurirgnpdetail from "../images/si-kurir-gnp-detail.png";
import sibookinggnp from "../images/si-booking-gnp.png";
import sibookinggnpdetail from "../images/si-booking-gnp-detail.png";
import sisalesgnp from "../images/si-sales-gnp.png";
import sisalesgnpdetail from "../images/si-sales-gnp-detail.png";
import mobilecektarifgnp from "../images/mobile-cek-tarif-gnp.png";
import mobilecektarifgnpdetail from "../images/mobile-cek-tarif-gnp-detail.png";
import mantracar from "../images/mantra-car.png";
import mantracardetail from "../images/mantra-car-detail.png";
import simanifestpcu from "../images/si-manifest-pcu.png";
import simanifestpcudetail from "../images/si-manifest-pcu-detail.png";
import mobilecargognp from "../images/mobile-cargo-gnp.png";
import mobilecargognpdetail from "../images/mobile-cargo-gnp-detail.png";
import myweb from "../images/my-web.png";
import mywebdetail from "../images/my-web-detail.png";
import gnp from "../images/gnp-company-profile.png";
import gnpdetail from "../images/gnp-company-profile-detail.png";
import gnpsistem from "../images/gnp-sistem-admin.png";
import gnpsistemdetail from "../images/gnp-sistem-admin-detail.png";
import qiranirentalmobil from "../images/qirani-rental.png";
import qiranirentalmobildetail from "../images/qirani-rental-detail.png";
const project = [
  {
    id: "1",
    name: "Graha Nusa Pratama System",
    img: gnpsistem,
    detail_img: gnpsistemdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "Ajax", "JQuery"],
    des: "In the process of developing this system, I was responsible for working on the front-end for the web application and the back-end for both mobile and web applications. This system is used for all logistics processes at PT. Graha Nusa Pratama, including booking shipping services with features such as automatic calculation of shipping rates and shipment tracking, Additionally, this system is also used for monitoring incoming and outgoing goods. and this system can also manage commission data and sales bonuses based on the revenue generated from the sale of shipping services. the three mobile applications that integrate with this system are used for customer booking shipping services, monitoring commission income and sales bonuses, and helping couriers monitor data on the number of goods they have sent. This system is already being used in four branches of PT. Graha Nusa Pratama: Yogyakarta, Jakarta, Gunungkidul, and Klaten. this system has seven actor levels of administrators: BigPic, Accounting, Incoming, Outgoing, Courier, Sales, and Customer. The Accounting admin level has access rights to manage sales commissions, sales bonuses, and topping up customer balances. The Incoming and Outgoing admin level has access rights for the process of monitoring the incoming and outgoing processes of goods. Actor levels Accounting, Incoming and Outgoing, has three sub levels namely: BigPic, PIC, and Staff. The BigPic admin level has complete access rights within the system. The PIC admin level only has access rights to manage data within their respective branches. The Staff admin level only has access rights to data that they manage."
  },
  {
    id: "2",
    name: "GNP Attendance Management System",
    img: sipresensignp,
    detail_img: sipresensignpdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "JQuery"],
    des: "In the process of developing this system, I was responsible for working the front-end for the web application and the back-end for both mobile and web applications. This system is used for monitoring employee attendance on a daily basis and includes features for comprehensive attendance data recap with overtime hours calculation for employees. Additionally, the system is also used for new employee registration and employee data management. This application is based on microservices architecture and directly interacts with the Courier Information System.",
  },
  {
    id: "3",
    name: "GNP Company Profile",
    img: gnp,
    detail_img: gnpdetail,
    technologys: ["Next.Js", "Tailwind", "Typescript"],
    des: "This website is developed using the Next.js framework with TypeScript language. The purpose of this website is to provide information about GNP Company, including details about freight forwarding service costs, shipping tracking, and other relevant information. This website is integrated with APIs from the GNP Booking System.",
  },
  {
    id: "4",
    name: "My Web Portfolio",
    img: myweb,
    detail_img: mywebdetail,
    technologys: ["React.js", "Bootstrap", "CSS3"],
    des: "This's my web portfolio, this website is developed using the react.js library with static content. This website aims to share all my information like my skills, my projects, and my experiences.",
  },
  {
    id: "5",
    name: "Manifest GNP System",
    img: simanifestgnp,
    detail_img: simanifestgnpdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "JQuery"],
    des: "In the process of developing this system, I was responsible for working on the front-end for the web application and the back-end for both mobile and web applications. This system is used for monitoring the incoming and outgoing processes of goods, additionally, the system can also be used to track the number of shipments to each region. This system is already being used in four branches of GNP company: Yogyakarta, Jakarta, Gunungkidul, and Klaten. this system has two levels of administrators: PIC and Staff. The PIC admin level has complete access rights within the Manifest GNP System, and the Staff admin level only has access rights to data that they personally manage. This application is based on microservices architecture and directly interacts with the Courier Information System.",
  },
  {
    id: "6",
    name: "Quiz System",
    img: sikuismhs,
    detail_img: sikuismhsdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "JQuery"],
    des: "This system is the final project of my college at Immanuel Christian University, This system has two user actors: lecturer and student. this system is used for conducting online quizzes and exams at Immanuel Christian University, Additionally, this system could also help lecturers to manage quizzes, manage quiz sessions, and process of recapitulating student quiz scores. This system is based on microservices architecture.",
  },
  {
    id: "7",
    name: "Sales GNP System",
    img: sisalesgnp,
    detail_img: sisalesgnpdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen"],
    des: "In the process of developing this system, I was responsible for working on the front-end for the web application and the back-end for both mobile and web applications. This system is used for monitoring commission income and sales bonuses. Additionally, it can also be utilized for member registration. This application is based on microservices architecture and directly interacts with the Booking GNP System.",
  },
  {
    id: "8",
    name: "Shipping Rate Checking GNP Application",
    img: mobilecektarifgnp,
    detail_img: mobilecektarifgnpdetail,
    technologys: ["Lumen"],
    des: "in the process of developing this system, I was responsible for working on the back-end. This application is used to check the shipping rates for each product delivery in various regions. This application is based on microservices architecture and directly interacts with the Booking GNP System.",
  },
  {
    id: "9",
    name: "MANTRA CAR",
    img: mantracar,
    detail_img: mantracardetail,
    technologys: ["Laravel", "Bootstrap"],
    des: "This website serves as a landing page for promoting car rental services and facilitating car rental package orders.",
  },
  {
    id: "10",
    name: "Manifest PCU System",
    img: simanifestpcu,
    detail_img: simanifestpcudetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "JQuery"],
    des: "In the process of developing this system, I was responsible for working for both the front-end and the back-end of this system, this system is used to collect data on incoming goods from the PCU to the GNP company. This system is based on microservices architecture.",
  },
  {
    id: "11",
    name: "Vehicle Condition Report Application",
    img: mobilecargognp,
    detail_img: mobilecargognpdetail,
    technologys: ["Lumen", "Go"],
    des: "In the process of developing this system, I was responsible for working the back-end for this mobile application, this application is used to create reports on the condition of vehicles in GNP Company. This application is based on microservices architecture.",
  },
  {
    id: "12",
    name: "Courier Information System",
    img: sikurirgnp,
    detail_img: sikurirgnpdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "JQuery"],
    des: "In the process of developing this system, I was responsible for working on the front-end for the web application and the back-end for both mobile and web applications. This system is used to help couriers monitor data on the number of goods they have sent, additionally, this system can also be used for registering new couriers. This application is based on microservices architecture and directly interacts with the Manifest GNP System.",
  },
  {
    id: "13",
    name: "Booking GNP System",
    img: sibookinggnp,
    detail_img: sibookinggnpdetail,
    technologys: ["Laravel", "Bootstrap", "Lumen", "Ajax", "JQuery"],
    des: "In the process of developing this system, I was responsible for working on the front-end for the web application and the back-end for both mobile and web applications. This system is used for booking shipping services and is equipped with features such as automatic calculation of shipping rates and shipment tracking. Additionally, the system can also manage commission data and sales bonuses based on the revenue generated from the sale of shipping services. This system is already being used in four branches of GNP company: Yogyakarta, Jakarta, Gunungkidul, and Klaten. this system has five levels of administrators: BigPic, PIC, Staff, ACC, and OP. The BigPic admin level has complete access rights within the system for booking shipping services. The PIC admin level only has access rights to manage data within their respective branches. The Staff admin level only has access rights to shipments that they personally manage. The ACC admin level has access rights to manage sales commissions, sales bonuses, and company cash collection data, as well as the process of topping up customer balances. Lastly, the OP admin level has access rights for the process of tracking GNP shipments. This application is based on microservices architecture and directly interacts with the Sales GNP System and Shipping Rate Checking GNP Application.",
  },
  {
    id: "14",
    name: "Qirani Rental Mobil",
    img: qiranirentalmobil,
    detail_img: qiranirentalmobildetail,
    technologys: ["Next.Js", "Tailwind", "Typescript"],
    des: "This website serves as a landing page for promoting car rental services and facilitating car rental package orders. You can visit this website on https://www.qiranirentalmobil.com/",
  },
];

export default project;
