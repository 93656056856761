import React, {useState, useEffect} from "react"
import Laravel from '../images/icons/laravel.png'
import CI from '../images/icons/ci.png'
import Bootstrap from '../images/icons/bootstrap.png'
import Reacticont from '../images/icons/react.png'
import Nodejs from '../images/icons/nodejs1.png'
import Html from '../images/icons/html5-1.png'
import Css from '../images/icons/css3.png'
import Mysql from '../images/icons/mysql.png'
import Js from '../images/icons/js.png'
import Jquary from '../images/icons/jquary.png'
import Php from '../images/icons/phpp.png'
import Pr from '../images/icons/pr.png'
import Obs from '../images/icons/obs.png'
import Canva from '../images/icons/canva.png'
import Figma from '../images/icons/figma.png'
import Filmora from '../images/icons/filmora.png'
import Ps from '../images/icons/ps.png'
import Word from '../images/icons/word.png'
import Pp from '../images/icons/pp.png'
import Go from '../images/icons/golang.png'
import Excel from '../images/icons/excel.png'
import Next from '../images/icons/next.png'
import Tw from '../images/icons/tw.png'
import Docker from '../images/icons/docker.png'
import Ts from '../images/icons/ts.png'
import Cat from '../images/gifs/cat.gif'
import Cat2 from '../images/gifs/cat2.gif'
import Cat3 from '../images/gifs/cat3.gif'
import Cat4 from '../images/gifs/cat4.gif'

function SkillList(props) {

  const [Line, setLine] = useState(true);

  useEffect(() => {
    function handleResize() {
      const mediaQuery = window.matchMedia("(max-width: 600px)");
      if (mediaQuery.matches) {
        setLine(false);
      } else {
        setLine(true);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [opacity,
    setOpacity] = useState(false)
  const changeOpacity = () => {
    if (window.scrollY >= 800) {
      setOpacity(true)
    } else {
      setOpacity(false)
    }
  }

  window.addEventListener('scroll', changeOpacity)

  const pictureArray = [Cat, Cat2, Cat3, Cat4];
  const randomIndex = Math.floor(Math.random() * pictureArray.length);
  const selectedPicture = pictureArray[randomIndex];

  const colorArray = ["darkblue", "#006666", "darkred", "#660066"];
  const randomIndexColor = Math.floor(Math.random() * colorArray.length);
  const selectedColor = colorArray[randomIndexColor];

  let [over, setOver] = React.useState(false);

  let iconstyle = {
    width: "50px",
    height: "50px",
    opacity: 0
  }

  let borderstyle = {
    color: "#7a7a7a"
  }

  let opacityicon = {
    opacity: 0.5
  }

  if (over) {
    iconstyle.opacity = 1;
    borderstyle.color = "#282828";
    opacityicon.opacity = 1;
  } else {
    iconstyle.opacity = 0;
    borderstyle.color = "#7a7a7a";
    opacityicon.opacity = 0.5;
  }

  function Icons() {
    if (props.icon == "front") {
      return (
        <div className="d-flex justify-content-center">
          <div style={opacityicon} className="d-flex justify-content-center">
            <div className="m-2">
              <img src={Reacticont} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Ts} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Tw} width="40px" className="border border-secondary rounded-circle"/>
            </div>
            <div className="m-2">
              <img src={Js} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Next} width="40px" className="rounded"/>
            </div>
            <div className="m-2">
              <img src={Bootstrap} width="40px"/>
            </div>
          </div>
        </div>
      )
    } else if (props.icon == "back") {
      return (
        <div className="d-flex justify-content-center">
          <div style={opacityicon} className="d-flex justify-content-center">
            <div className="m-2">
              <img src={Mysql} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Go} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Docker} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Laravel} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Php} width="40px"/>
            </div>
          </div>
        </div>
      )
    } else if (props.icon == "design") {
      return (
        <div className="d-flex justify-content-center">
          <div style={opacityicon} className="d-flex justify-content-center">
            <div className="m-2">
              <img src={Ps} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Figma} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Canva} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Obs} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Filmora} width="40px"/>
            </div>
          </div>
        </div>
      )
    } else if (props.icon == "office") {
      return (
        <div className="d-flex justify-content-center">
          <div style={opacityicon} className="d-flex justify-content-center">
            <div className="m-2">
              <img src={Word} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Excel} width="40px"/>
            </div>
            <div className="m-2">
              <img src={Pp} width="40px"/>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="col-sm-6">
      <div className="App">
        <img style={iconstyle} src={selectedPicture}/>
      </div>
      <div style={borderstyle}
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
        className="card rounded-0 skill">
        <div className="card-body">
          <h4 className="card-title text-center">{props.judul}</h4>
          <hr />
          <Icons/>
        </div>
      </div>
    </div>
   
  )
}

export default SkillList;