import React, { useEffect, useState } from "react";
import "../css/Loading.css";
import Loading1 from "../images/gifs/loading1.gif";

function Loading() {
  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="bg-loading">
          <div className="in-loading">
            <div className="anim-loading">
            <img src={Loading1} width="40px" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loading;
