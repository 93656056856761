import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {SnackbarProvider, useSnackbar} from 'notistack';
import '../css/Contact.css';

function Contact() {

  const form = useRef();

  function SnackBar() {

    const [opacity,
      setOpacity] = useState(false)
    const changeOpacity = () => {
      if (window.scrollY >= 4200) {
        setOpacity(true)
      } else {
        setOpacity(false)
      }
    }
  
    window.addEventListener('scroll', changeOpacity)

    const {enqueueSnackbar} = useSnackbar();

    // const handleClickVariant = (variant : VariantType) => () => {
    //   enqueueSnackbar('Send Message Success', {variant});
    // }

    const sendEmail = (e) => {
      e.preventDefault();

      const name_value = e.target[0].value;
      const email_value = e.target[1].value;
      const message_value = e.target[2].value;

      if (name_value != null && email_value != null && message_value != null) {
        enqueueSnackbar('Send Message Success', {variant: `success`});
      } else {
        enqueueSnackbar('Send Message Error', {variant: `error`});
      }

      emailjs
        .sendForm('service_2djh6l5', 'template_w974qov', form.current, 'ySy4yxS8xbw4mZ3px')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
      e.target.reset();
    };

    return (
      <div className='selection content-background' id="contact">
        <div className='d-flex flex-column flex-md-row contact-position'>
          <div className={opacity ? 'col scroll-text' : 'col m-3'}>
            <p className='contact-text'>Contact Me For Collaboration And Speaking Engagement Opportunities !</p>
          </div>
          <div className={opacity ? 'col scroll-form' : 'col'}>
            <div className='w-75 m-auto'>
              <div className='card-body'>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="exampleInputName"
                      placeholder='Name'
                      required/>
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder='Email'
                      required/>
                  </div>
                  <div className="mb-3">
                    <textarea
                      name="message"
                      className="form-control"
                      spellCheck="false"
                      id="exampleInputPassword1"
                      placeholder='Message'
                      required/>
                  </div>
                  <button type="submit" value="Send" className="btn btn-outline-warning">Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <SnackBar/>
    </SnackbarProvider>
  )
}

export default Contact;

//enable js validation