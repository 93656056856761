import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import ProjectList from "../element/ProjectList.js";
import "../css/Project.css";
import Loading from "../element/Loading.js";

function Project() {
  const [loadingProjectList, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const [opacity, setOpacity] = useState(false);
  const changeOpacity = () => {
    if (window.scrollY >= 1500) {
      setOpacity(true);
    } else {
      setOpacity(false);
    }
  };

  window.addEventListener("scroll", changeOpacity);

  return (
    <div className="selection content-background" id="project">
      <div className="container">
        <div className={opacity ? "project-image-scroll" : ""}>
          <h1 className="text-light project-text mt-3">Projects</h1>
          {loadingProjectList ? (
            <div className="m-5">
              <Loading />
            </div>
          ) : (
            <>
              <div>
                <ProjectList />
              </div>
              <div className="d-flex justify-content-center">
              <Link to="/all/project" className="btn btn-outline-warning mt-3">
                  See All
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Project;
