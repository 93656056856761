import React, {useState} from "react";
import '../css/Nav.css';
import { Link } from "react-router-dom";

function SubNav() {
  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-dark fixed-top nav-dark'>
        <div className="container">
          <a className="navbar-brand" href="#intro">Vinod Heryan</a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
            <li className="nav-item text-light">
                <Link className="nav-link" to="/all/project">Projects</Link>
              </li>
              <li className="nav-item text-light">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SubNav;