import React, {useState, useEffect} from "react"
import '../css/Nav.css';


function Nav() {

  const [home, setHome] = useState(true);
  const [about, setAbout] = useState(false);
  const [skills, setSkills] = useState(false);
  const [projects, setProjects] = useState(false);
  const [works, setWorks] = useState(false);
  const [contact, setContact] = useState(false);

  const changeNav = () => {
    if (window.scrollY <= 600) {
      setHome(true);
    } else {
      setHome(false);
    }

    if (window.scrollY >= 600 && window.scrollY < 1200) {
      setAbout(true);
    } else {
      setAbout(false);
    }

    if (window.scrollY >= 1200 && window.scrollY < 1800) {
      setSkills(true);
    } else {
      setSkills(false);
    }

    if (window.scrollY >= 1800 && window.scrollY < 2400) {
      setProjects(true);
    } else {
      setProjects(false);
    }

    if (window.scrollY >= 2400 && window.scrollY < 4600) {
      setWorks(true);
    } else {
      setWorks(false);
    }

    if (window.scrollY >= 4600) {
      setContact(true);
    } else {
      setContact(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-dark fixed-top nav-dark'>
        <div className="container">
          <a className="navbar-brand" href="#intro">Vinod Heryan</a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item text-light">
                <a className={home ? "nav-link active" : "nav-link"} id={"1"} href="#intro">Me</a>
              </li>
              <li className="nav-item">
                <a className={about ? "nav-link active" : "nav-link"} id={"2"} href="#about-me">About Me</a>
              </li>
              <li className="nav-item text-light">
                <a className={skills ? "nav-link active" : "nav-link"} id={"3"} href="#skills">Skills</a>
              </li>
              <li className="nav-item text-light">
                <a className={projects ? "nav-link active" : "nav-link"} id={"4"}  href="#project">Projects</a>
              </li>
              <li className="nav-item text-light">
                <a className={works ? "nav-link active" : "nav-link"} id={"5"}  href="#work">Work</a>
              </li>
              <li className="nav-item text-light">
                <a className={contact ? "nav-link active" : "nav-link"} id={"6"}  href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;