import "../css/Welcome.css";
import logo from "../images/V.png";

function Welcome() {
  return (
    <div className="selection content-background">
      <div className="continer">
        <div className="welcome-image d-flex justify-content-center">
          <img src={logo} alt="Logo" width="40%" />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
