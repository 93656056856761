import aboutme from "../images/about-me.jpg";
import React, { useState } from "react";
import "../css/AboutMe.css";

function AboutMe() {
  const [opacity, setOpacity] = useState(false);
  const changeOpacity = () => {
    if (window.scrollY >= 100) {
      setOpacity(true);
    } else {
      setOpacity(false);
    }
  };

  window.addEventListener("scroll", changeOpacity);

  return (
    <div className="selection content-background" id="about-me">
      <div className="container">
        <div className="d-flex flex-column flex-md-row my-5 align-items-center">
          <div className="col text-center">
            <img
              src={aboutme}
              className={opacity ? "avatar-about-me-scroll" : "avatar-about-me"}
            />
          </div>
          <div className="col">
            <div className={opacity ? "text-about-me-scroll" : "text-about-me"}>
              <h1 className="about-text">About Me</h1>
              <p>
                Hello, I'm Vinod Heryan, usually called Vinod or Nod. I am a
                software engineer and hold a Bachelor's degree in computer
                science from Immanuel Christian University. My journey in the
                tech industry has made me fall in love with the world of
                software engineering. One of the things I love about the world
                of software engineering is learning many new things about
                technology and being able to create something extraordinary.
                Currently, I'm proficient in working with Laravel, Lumen, Tailwind, Bootstrap,
                WordPress, ReactJS, and NextJS with Typescript to create
                responsive web applications. Additionally, I have experience in
                using Golang, Docker, Structured Query
                Language (SQL) and REST API architecture.
              </p>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1ME-ODiyraGFLhVWmfb74xw4qCKguQTTF/view?usp=sharing"
                className="btn btn-outline-warning mt-3"
              >
                <i className="fa fa-file-text-o"></i> Resume
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
