import data_project from "../data/DataProject";
import "../css/AllProject.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function AllProjectList() {
  const [loadedImages, setLoadedImages] = useState([]);

  let project = data_project;

  useEffect(() => {
    const loadImage = (src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, src]);
      };
      img.onerror = () => {
        console.log(`Error loading image: ${src}`);
      };
    };

    data_project.forEach((project) => {
      loadImage(project.img);
    });
  }, []);

  const data = project.map((project, index) => (
    <>
      {loadedImages.includes(project.img) ? (
        <div key={project.id} className="col project-container">
          <Link to={`/detail/project/${index}`}>
            <LazyLoadImage
              effect="blur"
              src={project.img}
              className="project-images"
              alt="img"
              loading="lazy"
            />
            <div className="overlay">
              <div className="text">{project.name}</div>
            </div>
          </Link>
        </div>
      ) : (
        <div key={project.id} className="col project-container">
          <Link to={`/detail/project/${index}`}>
            <p className="project-images-loading"></p>
            <div className="overlay">
              <div className="text">{project.name}</div>
            </div>
          </Link>
        </div>
      )}
    </>
  ));

  return (
    <div className="row gy-4 my-3 row-cols-1 row-cols-sm-2 row-cols-md-4">
      {data}
    </div>
  );
}

export default AllProjectList;
