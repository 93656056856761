import React, { useState } from "react";
import profile from "../images/bgrv.png";
import "../css/Intro.css";
import AudioPlayer from "react-audio-player";
import musik1 from "../audio/musik1.mp3";
import musik2 from "../audio/musik2.mp3";
import musik3 from "../audio/musik3.mp3";
import musik4 from "../audio/musik4.mp3";

function Intro() {
  const [audioPlaying, setAudioPlaying] = useState(false);

  function toggleAudio() {
    setAudioPlaying((prevAudioPlaying) => !prevAudioPlaying);
  }

  const musikArray = [musik1, musik2, musik3, musik4];
  const randomIndex = Math.floor(Math.random() * musikArray.length);
  const selectedMusik = musikArray[randomIndex];

  const [isLoading, setIsLoading] = useState(true);

  const img = new Image();
  img.src = profile;
  img.onload = () => {
    setIsLoading(false);
  };

  return (
    <div className="selection text-light content-background intro" id="intro">
      <div className="container avatar-transisi">
        <div className="d-flex flex-column flex-md-row my-5 align-items-center">
          <div className="col-md-6 name">
            <p>Hello</p>
            <p>
              I'm
              <span className="color-text-name"> Vinod Heryan</span>
            </p>
            <p>Software Engineer</p>
          </div>
          <div className="col-md-6 avatar-relative">
            {isLoading ? (
              <>
                <p className="avatar-loading"></p>
              </>
            ) : (
              <>
                <img src={profile} className="avatar" alt="profile" />
                <span className="back-avatar">
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="orange"
                      d="M55.7,-50.8C69.7,-41.6,77,-20.8,78,0.9C78.9,22.7,73.4,45.3,59.4,54.3C45.3,63.3,22.7,58.6,1.6,57C-19.4,55.4,-38.9,56.9,-52.9,47.9C-66.9,38.9,-75.4,19.4,-72,3.4C-68.6,-12.6,-53.2,-25.2,-39.2,-34.3C-25.2,-43.4,-12.6,-49.1,4.1,-53.2C20.8,-57.3,41.6,-59.9,55.7,-50.8Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </span>
              </>
            )}
          </div>
        </div>
        <div className="position-sosial-media">
          <a
            target="_blank"
            href="https://gitlab.com/Vinod_Heryan"
            className="sosial-media"
            rel="noreferrer"
          >
            <i className="fa fa-gitlab"></i>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/vinod-heryan-232060220"
            className="sosial-media"
            rel="noreferrer"
          >
            <i className="fa fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Intro;
