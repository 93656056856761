import React, { useState, useEffect } from "react";
import AllProjectList from "../element/AllProjectList";
import "../css/AllProject.css";
import Loading from "../element/Loading";

function AllProject() {
  const [loadingProjectList, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="selection content-background" id="project">
      <div className="container">
        {loadingProjectList ? (
          <>
          <div className="m-5">
            <Loading />
          </div>
          </> 
        ) : (
          <div>
            <h1 className="text-light all-project-text">All Project</h1>
            <div className="all-image">
              <AllProjectList />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllProject;
