import React, { useState } from 'react';
import AudioPlayer from "react-audio-player";
import musik1 from "../audio/musik1.mp3";
import musik2 from "../audio/musik2.mp3";
import musik3 from "../audio/musik3.mp3";
import musik4 from "../audio/musik4.mp3";
import musik5 from "../audio/musik5.mp3";
import musik6 from "../audio/musik6.mp3";
import musik7 from "../audio/musik7.mp3";
import musik8 from "../audio/musik8.mp3";

function AudioControl() {
  const [audioPlaying, setAudioPlaying] = useState(false);

  function toggleAudio() {
    setAudioPlaying((prevAudioPlaying) => !prevAudioPlaying);
  }

  const musikArray = [musik1, musik2, musik3, musik4, musik5, musik6, musik7, musik8];
  const randomIndex = Math.floor(Math.random() * musikArray.length);
  const selectedMusik = musikArray[randomIndex];

  return (
    <div>
      {audioPlaying && (
        <AudioPlayer src={selectedMusik} autoPlay={true} loop={true} />
      )}
      <div onClick={toggleAudio} className="musik-media">
        {audioPlaying ? (
          <i className="fa fa fa-music active"></i>
        ) : (
          <i className="fa fa fa-music"></i>
        )}
      </div>
    </div>
  );
}

export default AudioControl;
