import React, { useState, useEffect } from "react";
import data_project from "../data/DataProject";
import { useParams } from "react-router-dom";
import "../css/DetailProject.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function DetailProject() {
  const [isLoading, setIsLoading] = useState(true);

  const project = data_project;

  const id = useParams();

  const [selectedItem] = useState(id.id);

  const data_detail = project[selectedItem];

  const [Line, setLine] = useState(true);

  useEffect(() => {
    function handleResize() {
      const mediaQuery = window.matchMedia("(max-width: 600px)");
      if (mediaQuery.matches) {
        setLine(false);
      } else {
        setLine(true);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data_technology = data_detail.technologys.map((technology, index) =>
    Line ? (
      <div className="col-2">
        <div
          style={{
            borderRadius: "3px",
            fontFamily: "Times New Roman",
            fontSize: "20px",
            padding: "2px, 12px, 2px, 12px",
            outline: "#F8F8F8 solid 2px",
          }}
        >
          {technology}
        </div>
      </div>
    ) : (
      <div className="col-4 mb-3">
        <div
          style={{
            borderRadius: "3px",
            fontFamily: "Times New Roman",
            fontSize: "17px",
            padding: "2px, 12px, 2px, 12px",
          }}
        >
          {technology}
        </div>
      </div>
    )
  );

  const img = new Image();
  img.src = data_detail.detail_img;
  img.onload = () => {
    setIsLoading(false);
  }

  return (
    <div className="selection content-background" id="detailproject">
      <div className="container text-light">
        <div className="text-center d-flex justify-content-center">
          {isLoading ? (
            <>
            <p className="detail-project-images-loading"></p>
            </>
          ) : (
            <LazyLoadImage
              effect="blur"
              src={img.src}
              className="detail-project-images"
              alt="img"
              loading="lazy"
            />
          )}
        </div>
        {Line ? (
          <>
            <h1 className="text-light detail-project-name">
              {data_detail.name}
            </h1>
            <div className="des">Description :</div>
            <div className="detail-project-des">
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data_detail.des}</p>
            </div>
            <br />
            <br />
            <div className="detail-project-des row text-center d-flex justify-content-center">
              {data_technology}
            </div>
            <br />
          </>
        ) : (
          <>
            <h1 className="text-light text-center mt-4">{data_detail.name}</h1>
            <hr />
            <div className="des text-center">Description</div>
            <div className="detail-project-des">
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data_detail.des}</p>
            </div>
            <hr />
            <div className="des text-center">Technology</div>
            <div className="detail-project-des d-flex justify-content-center text-center row">
              {data_technology}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DetailProject;
