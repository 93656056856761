import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faLaptopCode,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import "../css/Work.css";

function Work() {
  const [opacity, setOpacity] = useState(false);
  const changeOpacity = () => {
    if (window.scrollY >= 2200) {
      setOpacity(true);
    } else {
      setOpacity(false);
    }
  };

  window.addEventListener("scroll", changeOpacity);

  const [boxShadow, setBoxShadow] = useState("-5px 7px orange");

  const [dateColor, setDateColor] = useState("#fff");

  useEffect(() => {
    function handleResize() {
      const mediaQuery = window.matchMedia("(max-width: 600px)");
      if (mediaQuery.matches) {
        setBoxShadow("5px 7px orange");
        setDateColor("black");
      } else {
        setBoxShadow("-5px 7px orange");
        setDateColor("#fff");
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const workBorder1 = {
    boxShadow,
    boxSizing: "border-box",
  };

  const workBorder2 = {
    boxShadow: "5px 7px orange",
    boxSizing: "border-box",
  };

  return (
    <div className="selection content-background" id="work">
      <div className="container">
        <br></br>
        <br></br>
        <div className={opacity ? "work-item" : ""}>
          <h1 className="text-light work-experience-text mt-3 mb-5">
            Work Experience
          </h1>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="11.2023 - Present"
              icon={<FontAwesomeIcon icon={faLaptopCode} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder1}
            >
              <h3 className="vertical-timeline-element-title">
                <i>Software Engineer</i>
              </h3>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faBuilding} /> K24Klik
              </h6>
              <hr />
              <ul>
                <li>
                  <i>Develop and maintain mobile applications using flutter.</i>
                </li>
                <li>
                  <i>Develop and maintain web applications.</i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="10.2022 - 10.2023"
              icon={<FontAwesomeIcon icon={faLaptopCode} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder2}
            >
              <h3 className="vertical-timeline-element-title">
                <i>Full Stack Engineer</i>
              </h3>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faBuilding} /> PT. Graha Nusa Pratama
              </h6>
              <hr />
              <ul>
                <li>
                  <i>Analyze the system based on the needs of the company.</i>
                </li>
                <li>
                  <i>Design, create and maintain database systems.</i>
                </li>
                <li>
                  <i>
                    Create and maintain APIs for web and mobile applications.
                  </i>
                </li>
                <li>
                  <i>Implement APIs for web applications.</i>
                </li>
                <li>
                  <i>Maintain company servers.</i>
                </li>
                <li>
                  <i>Deploy web applications to company servers.</i>
                </li>
                <li>
                  <i>
                    Responsible for all office electronic equipment, such as
                    computers and printers.
                  </i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="09.2021 - 02.2022"
              icon={<FontAwesomeIcon icon={faLaptopCode} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder1}
            >
              <h3 className="vertical-timeline-element-title">
                <i>Back End Developer</i>
              </h3>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faBuilding} /> PT. Novasoft Arjaya
                Technology
              </h6>
              <hr />
              <ul>
                <li>
                  <i>
                    Integration Progressive Web Apps (PWA) with Laravel for
                    NovaMedis project.
                  </i>
                </li>
                <li>
                  <i>Testing existing ERP by consume data using CodeIgniter.</i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="08.2020 - 09.2021"
              icon={<FontAwesomeIcon icon={faPeopleGroup} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder2}
            >
              <h3 className="vertical-timeline-element-title">
                <i>Coordinator - Tekominfo</i>
              </h3>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faPeopleGroup} /> Student Executive Board
                of Immanuel Christian University
              </h6>
              <hr />
              <ul>
                <li>
                  <i>Participating and Organizing seminar/workshop.</i>
                </li>
                <li>
                  <i>
                    Design/creating poster, certificate, and documents for
                    organizational purposes.
                  </i>
                </li>
                <li>
                  <i>Manage all social media of the organization.</i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="02.2021 - 06.2021"
              icon={<FontAwesomeIcon icon={faPeopleGroup} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder1}
            >
              <h4 className="vertical-timeline-element-title">
                <i>Teaching Assistant for Web Programming</i>
              </h4>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faBuilding} /> Immanuel Christian
                University
              </h6>
              <hr />
              <ul>
                <li>
                  <i>Assists lecturers for reviewing students assignments.</i>
                </li>
                <li>
                  <i>
                    Participate in teaching 2nd year students in Web Programming
                    courses at the Faculty Of Physics & Computer, Immanuel
                    Christian University.
                  </i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2019 - 2020"
              icon={<FontAwesomeIcon icon={faPeopleGroup} />}
              iconStyle={{ background: "orange", color: "#fff" }}
              contentStyle={workBorder2}
            >
              <h3 className="vertical-timeline-element-title">
                <i>Head of PSG Organization</i>
              </h3>
              <h6 className="vertical-timeline-element-subtitle mt-2">
                <FontAwesomeIcon icon={faPeopleGroup} /> Programming Study Group
              </h6>
              <hr />
              <ul>
                <li>
                  <i>Prepare material to be taught to students.</i>
                </li>
                <li>
                  <i>
                    Teach students in Java Programming (OOP, Logical &
                    Analytical Programming).
                  </i>
                </li>
                <li>
                  <i>
                    Participating and Organizing programming seminar/workshop.
                  </i>
                </li>
                <li>
                  <i>
                    Design/creating poster, certificate, and documents for
                    organizational purposes.
                  </i>
                </li>
              </ul>
            </VerticalTimelineElement>
            <style jsx>{`
              .vertical-timeline-element-date {
                color: ${dateColor};
                font-family: Calibri;
              }
            `}</style>
          </VerticalTimeline>
        </div>
      </div>
    </div>
  );
}

export default Work;
