import '../css/Footer.css';
function Footer() {
  return (
    <div>
      <div className="card-footer footer-1">
        <p className='footer-text'>© 2023 by Vinod Heryan</p>
      </div>
    </div>
  )
}

export default Footer;